<template>
        <section class="products-holder" :color="`${zamuPurple2}`" >   
            <v-layout justify-space-around justify-center>
                <v-flex xs12 sm12 offset-sm1 class="mt-10">
                         <v-card flat tile color="" width="90%" class="transparent main-content-products" :class="{'full-width': is_screen_small}">
                            <v-card-title primary-title class="justify-center px-0">
                                   <h3 v-if="!is_screen_small" class="zamu-heading transact-heading product-heading">Zamupay Messaging Services</h3><br />
                                   <h3 v-if="is_screen_small" class="zamu-heading transact-heading product-heading">Zamupay <br />Messaging <br /> Services</h3><br />

                                    <v-card tile flat :color="`${zamuGrey1}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-white"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                            <v-row align="center" justify="center">
                                <v-col cols="12"  lg="4" md="4" sm="6" xs="12" class="" v-for="(product, index) in products" :key="index">
                                    <v-card tile flat :color="`${product.color}`" min-height="343"  align="center" justify="center">
                                        <v-card-text class="section-services" align="left" justify="left">
                                            <v-row>
                                                  <v-col  lg="12" md="12" sm="12" xs="12"  cols="12" align="center" justify="center">
                                                        <v-icon size="28" :color="`${zamuBlack}`" :class="`${product.class}-icon`">{{ product.icon }}</v-icon>

                                                        <v-divider :color="`${zamuBlack}`" class="icon-divider top-divider" :class="`${product.class}-hr`"></v-divider>
                                                        <v-divider :color="`${zamuBlack}`" class="icon-divider" :class="`${product.class}-hr`"></v-divider>

                                                    </v-col>
                                                <v-col cols="12" lg="12" md="12" sm="12" xs="12" align="center" justify="center" :class="`${product.class}`">
                                                    <h3 class="zamu-sub-heading product-header mt-0">{{ product.title }}</h3>
                                                    <p class="mt-4">{{ product.text }}</p>
                                                </v-col>

                                            </v-row>
                                        </v-card-text>                                    
                                    </v-card>
                                </v-col>
                            </v-row>
                         </v-card>
                </v-flex>
            </v-layout>
        </section>
  
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: "Services",
    mixins: [colorMixin, coreMixin],
     computed: {
        products () {
            const data = [
                {
                    image: 'Pie_chart.png',
                    title: 'Bulk Messaging',
                    text: 'Our seamless, behavior-triggered messaging and USSD gateway provides for the sending of Bulk SMS, SMS payment reminders, scheduled and repeating SMS, billing reports, personalized SMS marketing, SMS group messaging and analytics – in real time.',
                    color: '#d29f2a',
                    icon: 'mdi-message-text-outline',

                },
                 {
                    image: 'Pie_chart.png',
                    title: 'USSD Bulk',
                    text: 'Improve your business effectiveness by utilizing the benefits of USSD such as conducting online surveys, sales and marketing promotions, information request voucher and coupon.  Integrate our simple and reliable Zamupay messaging USSD API today and enhance your business outcomes by delivering scalable solutions to your customers.',
                    color: '#ffffff',
                    icon: 'mdi-cellphone-nfc',

                },
                 {
                    image: 'Note.png',
                    title: 'Payments',
                    text: 'Integrate the Zamupay payment API that will give your users an easy, secure and efficient way to make payments through mobile and bank transfers.',
                    color: '#d29f2a',
                    icon: 'mdi-credit-card-outline',
                }
             
            ];
            return data;

        },

    },

}
</script>

<style>
.icon-holder {
    height: 100%;
    width: 100%;
}
.icon-holder .v-icon {
    font-size: 40px !important;
    color: #161616;
}
.full-width {
    width: 100% !important;
}

</style>